define("ember-svg-jar/inlined/credit-card-alt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 11.75V7h18v4.75q0 .516-.367.883T16.75 13H1.25q-.516 0-.883-.367T0 11.75zM5 10v1h3v-1H5zm-3 0v1h2v-1H2zm14.75-9q.516 0 .883.367T18 2.25V4H0V2.25q0-.516.367-.883T1.25 1h15.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "14",
      "viewBox": "0 0 18 14"
    }
  };
});