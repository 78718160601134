define("ember-svg-jar/inlined/fi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#fff\" d=\"M0 0h640v480H0z\"/><path fill=\"#002f6c\" d=\"M0 174.5h640v131H0z\"/><path fill=\"#002f6c\" d=\"M175.5 0h130.9v480h-131z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 640 480"
    }
  };
});