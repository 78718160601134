define("ember-svg-jar/inlined/jp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><clipPath id=\"a\"><path fill-opacity=\".7\" d=\"M-88 32h640v480H-88z\"/></clipPath></defs><g fill-rule=\"evenodd\" stroke-width=\"1pt\" clip-path=\"url(#a)\" transform=\"translate(88 -32)\"><path fill=\"#fff\" d=\"M-128 32h720v480h-720z\"/><circle cx=\"523.1\" cy=\"344.1\" r=\"194.9\" fill=\"#bc002d\" transform=\"translate(-168.4 8.6) scale(.76554)\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 640 480"
    }
  };
});