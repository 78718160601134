define("ember-svg-jar/inlined/circle-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"8\" cy=\"8\" r=\"8\"/><path d=\"M5 11.095L11.095 5M5 5l6.095 6.095\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});