define("ember-svg-jar/inlined/map-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><filter id=\"a\" x=\"-40%\" y=\"-40%\" width=\"180%\" height=\"180%\" filterUnits=\"userSpaceOnUse\"><feGaussianBlur in=\"SourceAlpha\" stdDeviation=\"2\"/><feOffset dx=\"5\" dy=\"5\" result=\"offsetblur\"/><feOffset dx=\"-5\" dy=\"-5\" result=\"offsetblur\"/><feComponentTransfer><feFuncA type=\"linear\" slope=\".6\"/></feComponentTransfer><feMerge><feMergeNode/><feMergeNode in=\"SourceGraphic\"/><feMergeNode in=\"SourceGraphic\"/></feMerge></filter><mask id=\"b\" x=\"0\" y=\"0\" width=\"100%\" height=\"100%\"><rect width=\"100%\" height=\"100%\" fill=\"#fff\" fill-opacity=\".5\"/><circle r=\"180\" transform=\"translate(200 200)\"/></mask></defs><circle r=\"178\" fill=\"none\" class=\"text-accent stroke-current stroke-4\" transform=\"translate(200 200)\"/><circle r=\"10\" stroke=\"#fff\" class=\"text-accent fill-current stroke-4\" filter=\"url(#a)\" transform=\"translate(200 200)\"/><rect width=\"100%\" height=\"100%\" mask=\"url(#b)\"/>",
    "attrs": {
      "viewBox": "0 0 400 400"
    }
  };
});