define("ember-is-mobile/initializers/is-mobile-service-injector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  /**
   * isMobile service injector
   *
   * Supports auto injecting the isMobile service app-wide.
   * Generated by the ember-is-mobile addon.
   */
  function initialize() {
    const application = arguments[1] || arguments[0];
    application.inject('controller', 'isMobile', 'service:isMobile');
    application.inject('component', 'isMobile', 'service:isMobile');
    application.inject('route', 'isMobile', 'service:isMobile');
    application.inject('view', 'isMobile', 'service:isMobile');
  }
  var _default = _exports.default = {
    name: 'isMobile',
    initialize
  };
});