define("ember-svg-jar/inlined/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.5 0h-15A2.502 2.502 0 000 2.5v11.25c0 1.379 1.121 2.5 2.5 2.5h3.75v3.281a.468.468 0 00.746.375l4.879-3.656H17.5c1.379 0 2.5-1.121 2.5-2.5V2.5C20 1.121 18.879 0 17.5 0zm1.25 13.75c0 .688-.563 1.25-1.25 1.25h-6.043l-.332.25L7.5 17.969V15h-5c-.688 0-1.25-.563-1.25-1.25V2.5c0-.688.563-1.25 1.25-1.25h15c.688 0 1.25.563 1.25 1.25v11.25zm-5.79-4.48c-.733.863-1.812 1.355-2.96 1.355s-2.227-.492-2.96-1.352a.63.63 0 00-.88-.07.626.626 0 00-.07.883c.969 1.137 2.394 1.789 3.91 1.789s2.941-.652 3.914-1.793a.626.626 0 10-.953-.813zM7.5 7.188c.52 0 .938-.418.938-.938s-.418-.938-.938-.938-.938.418-.938.938.418.938.938.938zm5 0c.52 0 .938-.418.938-.938s-.418-.938-.938-.938-.938.418-.938.938.418.938.938.938z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
});