define("ember-svg-jar/inlined/tm_mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11 7.75v-.5a.25.25 0 00-.25-.25h-5.5a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h5.5a.25.25 0 00.25-.25zM5.25 6h5.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25h-5.5a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25zm10.206-.859c-.062-.05-.693-.56-1.456-1.173V3a1 1 0 00-1-1h-1.452l-.319-.254C10.54 1.193 9.358 0 8 0 6.65 0 5.48 1.179 4.77 1.746L4.453 2H3a1 1 0 00-1 1v.968C1.234 4.584.602 5.093.548 5.138.2 5.423 0 5.848 0 6.296V14.5A1.5 1.5 0 001.5 16h13a1.5 1.5 0 001.5-1.5V6.297c0-.447-.2-.871-.544-1.156zM8 1c.68 0 1.395.522 1.973 1H6.028C6.608 1.523 7.323 1 8 1zM3 3h10v5.417c-1.01.813-2.04 1.639-2.706 2.167C9.768 11.004 8.726 12.012 8 12c-.725.012-1.767-.996-2.294-1.416A534.062 534.062 0 013 8.417V3zM1 6.296a.5.5 0 01.183-.386c.038-.032.363-.293.817-.659v2.361l-1-.805v-.51zM15 14.5a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5V8.09a887.28 887.28 0 004.084 3.277c.64.51 1.773 1.641 2.916 1.633 1.14.008 2.258-1.108 2.916-1.633.982-.78 2.748-2.2 4.084-3.276V14.5zm0-7.693l-1 .805v-2.36l.819.66a.499.499 0 01.181.385v.51z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16"
    }
  };
});