define("ember-svg-jar/inlined/comments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.778 5.556h-3.334V2.222A2.224 2.224 0 0012.222 0h-10A2.225 2.225 0 000 2.222v5.556C0 9.003.997 10 2.222 10h1.111v1.806a.416.416 0 00.664.333l2.67-1.51v2.704c0 1.226.996 2.223 2.222 2.223h3.333l3.781 2.138a.416.416 0 00.664-.333v-1.805h1.11A2.225 2.225 0 0020 13.333V7.778a2.224 2.224 0 00-2.222-2.222zM2.222 8.889c-.61 0-1.11-.5-1.11-1.111V2.222c0-.61.5-1.11 1.11-1.11h10c.611 0 1.111.5 1.111 1.11v5.556c0 .61-.5 1.11-1.11 1.11H7.485l-.253.147-2.789 1.576V8.89H2.222zm16.667 4.444c0 .611-.5 1.111-1.111 1.111h-2.222v1.723L12.77 14.59l-.254-.146H8.89c-.611 0-1.111-.5-1.111-1.11V10h4.444a2.224 2.224 0 002.222-2.222V6.667h3.334c.61 0 1.11.5 1.11 1.11v5.556z\"/>",
    "attrs": {
      "width": "20",
      "height": "19",
      "viewBox": "0 0 20 19"
    }
  };
});