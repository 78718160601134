define("ember-svg-jar/inlined/will_call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.305 8.998h10.389c.26 0 .472-.225.472-.5V1.5c0-.275-.212-.5-.472-.5H3.305c-.26 0-.472.225-.472.5v6.999c0 .274.213.5.472.5zM7.555 2h1.889v2.38l-.944-.5-.945.5V2zM3.778 2H6.61v3.999l1.889-1 1.888 1v-4h2.834v6H3.778v-6zm12.699 9.423a1.505 1.505 0 00-1.039-.419 1.58 1.58 0 00-1.021.381l-1.818 1.54a.3.3 0 01-.198.073h-1.228c.136-.3.192-.647.142-1.01a1.73 1.73 0 00-1.706-1.49H5.351c-.614 0-1.21.21-1.7.6l-1.134.9H.237a.244.244 0 00-.237.25v.5c0 .137.106.25.236.25h2.597l1.384-1.1c.328-.26.726-.4 1.134-.4h4.33c.392 0 .707.334.707.75 0 .415-.315.75-.708.75H7.083c-.26 0-.472.225-.472.5s.212.5.472.5h5.318c.286 0 .564-.104.788-.291l1.818-1.537a.666.666 0 01.431-.163c.148 0 .292.053.407.163a.689.689 0 01-.026 1.018l-2.975 2.522c-.224.19-.502.29-.788.29H.236c-.13 0-.236.113-.236.25v.5c0 .138.106.25.236.25h11.82c.502 0 .986-.181 1.375-.512l2.978-2.522c.36-.306.575-.762.59-1.25a1.726 1.726 0 00-.522-1.293z\"/>",
    "attrs": {
      "viewBox": "0 0 17 18"
    }
  };
});