define("ember-svg-jar/inlined/modal-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M6 18L18 6M6 6l12 12\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "aria-hidden": "true"
    }
  };
});