define("ember-svg-jar/inlined/flash_seats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.25 5H5.644L6.975.944A.753.753 0 006.25 0h-4.5a.75.75 0 00-.743.65l-1 7.5c-.06.45.29.85.743.85h3.71l-1.441 6.078a.75.75 0 001.378.547l5.5-9.5A.749.749 0 009.25 5zm-4.865 8.653l1.047-4.425.29-1.231H1.035L1.969 1h3.934L4.694 4.688 4.263 6h4.553l-4.431 7.653z\"/>",
    "attrs": {
      "viewBox": "0 0 10 16"
    }
  };
});