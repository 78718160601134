define("ember-svg-jar/inlined/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19 17a2 2 0 01-2 2H3a2 2 0 01-2-2V4a2 2 0 012-2h2V.5a.5.5 0 011 0V2h8V.5a.5.5 0 011 0V2h2a2 2 0 012 2v13zM2 8v9a1 1 0 00.883.993L3 18h14a1 1 0 00.993-.883L18 17V8H2zm0-1h16V4a1 1 0 00-.883-.993L17 3h-2v.5a.5.5 0 01-1 0V3H6v.5a.5.5 0 01-1 0V3H3a1 1 0 00-.993.883L2 4v3z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
});