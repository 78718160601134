define("ember-is-mobile/services/is-mobile", ["exports", "@ember/service", "@ember/object", "@ember/application", "@ember/utils", "ember-is-mobile"], function (_exports, _service, _object, _application, _utils, _emberIsMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * The attributes returned by isMobile are accessible. However, they should be
   * accessed using the `get` helper, since they may be undefined if the user
   * agent header is blank.
   */
  var _default = _exports.default = _service.default.extend({
    fastboot: (0, _object.computed)(function () {
      return (0, _application.getOwner)(this).lookup('service:fastboot');
    }),
    init() {
      this._super(...arguments);
      let queries;
      if ((0, _object.get)(this, 'fastboot.isFastBoot')) {
        const headers = (0, _object.get)(this, 'fastboot.request.headers');
        let userAgent = (0, _object.get)(headers, 'headers.user-agent');

        // isMobile tries to fetch `navigator` if we give it a blank user agent.
        if ((0, _utils.isBlank)(userAgent)) {
          return;
        }
        userAgent = userAgent[0];
        queries = (0, _emberIsMobile.default)(userAgent);
      } else {
        queries = _emberIsMobile.default;
      }
      for (let media in queries) {
        (0, _object.set)(this, media, queries[media]);
      }
    }
  });
});