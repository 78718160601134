define("ember-svg-jar/inlined/alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.543 10.463C12.668 9.63 12 8.76 12 5.81c0-2.488-1.98-4.516-4.5-4.762V.5a.5.5 0 10-1 0v.549C3.98 1.294 2 3.322 2 5.809c0 2.95-.67 3.822-1.542 4.654a1.452 1.452 0 00-.352 1.601c.228.57.78.94 1.394.936h11a1.49 1.49 0 001.394-.938 1.452 1.452 0 00-.351-1.6zM12.5 12h-11c-.444 0-.667-.515-.354-.813C2.236 10.15 3 8.99 3 5.81 3 3.704 4.788 2 7 2c2.212 0 4 1.704 4 3.81 0 3.167.757 4.333 1.852 5.377.316.3.09.813-.352.813zM7 15a1 1 0 01-1-1H5a2 2 0 104 0H8a1 1 0 01-1 1zm-.266-7h.532a.254.254 0 00.25-.234l.218-3.5A.249.249 0 007.484 4h-.968a.248.248 0 00-.25.266l.218 3.5a.25.25 0 00.25.234zM7 8.75a.75.75 0 100 1.5.75.75 0 000-1.5z\"/>",
    "attrs": {
      "width": "14",
      "height": "16",
      "viewBox": "0 0 14 16"
    }
  };
});