define("ember-svg-jar/inlined/privacy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.45 3.947L11.171.567A1.849 1.849 0 009.848 0H1.875C.84.004 0 .87 0 1.937v16.755c0 1.067.84 1.933 1.875 1.933h11.25c1.035 0 1.875-.866 1.875-1.933V5.316c0-.511-.2-1.007-.55-1.369zm-.884.914a.637.637 0 01.164.298H10V1.313c.11.028.207.085.29.17l3.276 3.378zm-.441 14.475H1.875a.637.637 0 01-.625-.644V1.937c0-.354.281-.644.625-.644H8.75V5.48a.95.95 0 00.938.967h4.062v12.244a.637.637 0 01-.625.644zM11.25 9.51v.322c0 .266-.21.484-.469.484H4.22a.478.478 0 01-.469-.484V9.51c0-.266.21-.483.469-.483h6.562c.258 0 .469.217.469.483zm0 2.578v.322c0 .266-.21.483-.469.483H4.22a.477.477 0 01-.469-.483v-.322c0-.266.21-.484.469-.484h6.562c.258 0 .469.218.469.484zm0 2.577v.322c0 .266-.21.484-.469.484H4.22a.478.478 0 01-.469-.484v-.322c0-.266.21-.483.469-.483h6.562c.258 0 .469.217.469.483z\"/>",
    "attrs": {
      "width": "15",
      "height": "21",
      "viewBox": "0 0 15 21"
    }
  };
});