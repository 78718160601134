define("ember-is-mobile/index", ["exports", "ismobilejs"], function (_exports, _ismobilejs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* globals FastBoot */

  function isMobile() {
    if (typeof FastBoot !== 'undefined') {
      // Load up the node package in FastBoot. It should return the function
      // without instantiating.
      return FastBoot.require('ismobilejs');
    } else {
      // In the browser, get the instantiated global.
      return _ismobilejs.default;
    }
  }
  var _default = _exports.default = isMobile();
});