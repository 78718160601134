define("ember-svg-jar/inlined/calendar-times-o", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.68 10.82l-.359.359q-.07.07-.172.07t-.18-.07L6.5 9.702l-1.469 1.477q-.078.07-.18.07t-.172-.07l-.359-.359q-.07-.07-.07-.172t.07-.18l1.477-1.469L4.32 7.53q-.07-.078-.07-.18t.07-.172l.359-.359q.07-.07.172-.07t.18.07L6.5 8.288l1.469-1.469q.078-.07.18-.07t.172.07l.359.359q.07.07.07.172t-.07.18L7.211 8.999l1.469 1.469q.07.078.07.18t-.07.172zM1 13h11V5H1v8zm3-9.5V1.25q0-.109-.07-.18T3.75 1h-.5q-.109 0-.18.07T3 1.25V3.5q0 .109.07.18t.18.07h.5q.109 0 .18-.07T4 3.5zm6 0V1.25q0-.109-.07-.18T9.75 1h-.5q-.109 0-.18.07T9 1.25V3.5q0 .109.07.18t.18.07h.5q.109 0 .18-.07T10 3.5zm3-.5v10q0 .406-.297.703T12 14H1q-.406 0-.703-.297T0 13V3q0-.406.297-.703T1 2h1v-.75q0-.516.367-.883T3.25 0h.5q.516 0 .883.367T5 1.25V2h3v-.75q0-.516.367-.883T9.25 0h.5q.516 0 .883.367T11 1.25V2h1q.406 0 .703.297T13 3z\"/>",
    "attrs": {
      "viewBox": "0 0 13 14"
    }
  };
});