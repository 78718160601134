define("ember-svg-jar/inlined/lv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill-rule=\"evenodd\"><path fill=\"#fff\" d=\"M0 0h640v480H0z\"/><path fill=\"#981e32\" d=\"M0 0h640v192H0zm0 288h640v192H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 640 480"
    }
  };
});