define("ember-svg-jar/inlined/ticketfly", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style/></defs><title>Ticketfly Lockup RGB</title><path class=\"text-on-background fill-current\" d=\"M99.45 150a23.54 23.54 0 11-23.54-23.54A23.54 23.54 0 0199.45 150\" transform=\"translate(-52.37 -126.46)\"/><path class=\"text-background fill-current\" d=\"M34.9 15.78v-5.34l-22.71 5.25v5.34l8.1-1.87v6.16l-4.04.93v5.34l4.04-.93v6.96l6.5-1.5v-6.96l4.06-.94v-5.34l-4.06.94v-6.16l8.11-1.88z\"/><path class=\"text-on-background fill-current\" d=\"M150.57 140a17 17 0 014 .5v5.88a7.39 7.39 0 00-3.28-.82 4.3 4.3 0 00-4.54 4.43c0 2.61 1.83 4.43 4.71 4.43a5.6 5.6 0 003.11-.91v6a21.8 21.8 0 01-3.57.49c-6.09 0-10.27-4.07-10.27-10a9.62 9.62 0 013-7.29 9.94 9.94 0 016.92-2.7m-17.89 19.89h6.08v-19.4h-6.08zM173 140.5h-6.42l-3.68 7.6v-7.6h-6.08v19.39h6.08v-9.59l4.06 9.59h6.73l-4.92-10.73zm-58.14 5h5v14.36h6.09v-14.33h4.73v-5h-15.83zm75.13 0h4.94v14.36H201v-14.33h4.73v-5H190zm-14.79 14.39h13v-5.39h-6.94v-2.08h5.23v-5h-5.23v-1.93h6.94v-5h-13zm66.26-19.39l-1.91 5.64-2.32-5.64h-6.5l5.92 12.71-2.58 6.68h6.45l7.11-19.39h-6.17zm-13.35 14v-14H222v19.39h10l2.11-5.39zm-20.43 5.39h6.09v-6.74h4.65v-5.09h-4.65v-2.53h6.37v-5h-12.46z\" transform=\"translate(-52.37 -126.46)\"/>",
    "attrs": {
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 195.27 47.09"
    }
  };
});