define("ember-svg-jar/inlined/ch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill-rule=\"evenodd\" stroke-width=\"1pt\"><path fill=\"red\" d=\"M0 0h640v480H0z\"/><g fill=\"#fff\"><path d=\"M170 195h300v90H170z\"/><path d=\"M275 90h90v300h-90z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 640 480"
    }
  };
});