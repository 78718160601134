define("ember-svg-jar/inlined/angle-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M5.073 6.59L.177 1.626a.62.62 0 010-.867l.571-.58a.6.6 0 01.854 0L5.5 4.113 9.398.179a.6.6 0 01.854 0l.57.58a.617.617 0 010 .867L5.929 6.59a.598.598 0 01-.855 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "11",
      "height": "8",
      "viewBox": "0 0 11 8"
    }
  };
});