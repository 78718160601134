define("ember-svg-jar/inlined/physical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.75 4.912c.137 0 .25-.11.25-.245v-.492a.248.248 0 00-.25-.245h-7.5c-.137 0-.25.11-.25.245v.492c0 .135.113.245.25.245h7.5zm11 5.895H19V7.488a1.95 1.95 0 00-.587-1.388L15.79 3.525a2.01 2.01 0 00-1.413-.578H13V1.431C13 .64 12.306 0 11.456 0H3.544C2.694 0 2 .642 2 1.43v.535H.25c-.138 0-.25.11-.25.246v.49c0 .136.113.246.25.246h7.5c.138 0 .25-.11.25-.245V2.21a.248.248 0 00-.25-.246H3V1.43c0-.249.244-.449.544-.449h7.912c.3 0 .544.2.544.449v9.376H6.487A2.493 2.493 0 003 10.327V7.86H2v4.42c0 1.358 1.119 2.457 2.5 2.457S7 13.637 7 12.28c0-.17-.019-.332-.05-.492h6.1c-.034.16-.05.323-.05.492 0 1.357 1.119 2.456 2.5 2.456s2.5-1.1 2.5-2.456c0-.17-.019-.332-.05-.492h1.8c.137 0 .25-.11.25-.245v-.491a.25.25 0 00-.25-.246zM4.5 13.754c-.828 0-1.5-.66-1.5-1.473 0-.814.672-1.474 1.5-1.474s1.5.66 1.5 1.474c0 .813-.672 1.473-1.5 1.473zM13 3.93h1.378c.263 0 .522.104.706.288l2.622 2.576c.025.025.035.059.056.086H13V3.93zm2.5 9.824c-.828 0-1.5-.66-1.5-1.473 0-.814.672-1.474 1.5-1.474s1.5.66 1.5 1.474c0 .813-.672 1.473-1.5 1.473zm2.5-2.947h-.512c-.457-.593-1.175-.982-1.988-.982s-1.531.39-1.988.982H13V7.86h5v2.947zM8 6.632V6.14a.248.248 0 00-.25-.245H.25c-.138 0-.25.11-.25.245v.492c0 .135.113.245.25.245h7.5c.138 0 .25-.11.25-.245z\"/>",
    "attrs": {
      "viewBox": "0 0 20 15"
    }
  };
});