define("ember-svg-jar/inlined/angle-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M5.073 1.744L.177 6.708a.62.62 0 000 .867l.571.579c.236.239.618.24.854 0L5.5 4.222l3.898 3.934a.6.6 0 00.854-.001l.57-.58a.616.616 0 000-.866L5.929 1.744a.598.598 0 00-.855 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "11",
      "height": "8",
      "viewBox": "0 0 11 8"
    }
  };
});