define("ember-svg-jar/inlined/comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.375 0H2.625C1.177 0 0 1.226 0 2.734v12.304c0 1.508 1.177 2.734 2.625 2.734h3.938v3.59c0 .418.459.661.783.413l5.123-4.003h5.906c1.448 0 2.625-1.226 2.625-2.734V2.734C21 1.226 19.823 0 18.375 0zm-6.563 11.279a.335.335 0 01-.328.341H5.578a.336.336 0 01-.328-.341v-.684c0-.188.148-.342.328-.342h5.906c.18 0 .329.154.329.342v.684zm3.938-4.102a.337.337 0 01-.328.342H5.578a.337.337 0 01-.328-.342v-.683c0-.188.148-.342.328-.342h9.844c.18 0 .328.154.328.342v.683z\"/>",
    "attrs": {
      "width": "21",
      "height": "22",
      "viewBox": "0 0 21 22"
    }
  };
});