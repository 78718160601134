define("ember-svg-jar/inlined/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.33.839c3.03 0 5.515 2.518 5.515 5.661 0 3.127-2.466 5.661-5.514 5.661-3.044 0-5.514-2.53-5.514-5.661C.817 3.376 3.283.839 6.33.839m0-.839C2.835 0 0 2.911 0 6.5 0 10.09 2.835 13 6.33 13c3.497 0 6.332-2.91 6.332-6.5 0-3.589-2.835-6.5-6.331-6.5zm-.92 9.016h.307V5.871h-.306a.31.31 0 01-.306-.315v-.21a.31.31 0 01.306-.314h1.225a.31.31 0 01.307.315v3.67h.306c.17 0 .306.14.306.314v.21a.31.31 0 01-.306.314H5.412a.31.31 0 01-.306-.315v-.21a.31.31 0 01.306-.314zm.92-6.29a.828.828 0 00-.817.839c0 .463.366.838.817.838a.828.828 0 00.817-.838.829.829 0 00-.817-.84z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13",
      "height": "13",
      "viewBox": "0 0 13 13"
    }
  };
});