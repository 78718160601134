define("ember-svg-jar/inlined/zoom-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.588 4.571h-3.16v-3.16C7.429.636 6.778 0 6 0c-.777 0-1.429.635-1.429 1.412v3.16h-3.16C.636 4.571 0 5.222 0 6c0 .777.635 1.429 1.412 1.429h3.16v3.16C4.571 11.364 5.222 12 6 12c.777 0 1.429-.635 1.429-1.412v-3.16h3.16C11.364 7.429 12 6.778 12 6c0-.777-.635-1.429-1.412-1.429z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12"
    }
  };
});