define("ember-svg-jar/inlined/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M506 114H134a6 6 0 01-6-6V84a6 6 0 016-6h372a6 6 0 016 6v24a6 6 0 01-6 6zm6 154v-24a6 6 0 00-6-6H134a6 6 0 00-6 6v24a6 6 0 006 6h372a6 6 0 006-6zm0 160v-24a6 6 0 00-6-6H134a6 6 0 00-6 6v24a6 6 0 006 6h372a6 6 0 006-6zM84 120V72c0-6.627-5.373-12-12-12H24c-6.627 0-12 5.373-12 12v48c0 6.627 5.373 12 12 12h48c6.627 0 12-5.373 12-12zm0 160v-48c0-6.627-5.373-12-12-12H24c-6.627 0-12 5.373-12 12v48c0 6.627 5.373 12 12 12h48c6.627 0 12-5.373 12-12zm0 160v-48c0-6.627-5.373-12-12-12H24c-6.627 0-12 5.373-12 12v48c0 6.627 5.373 12 12 12h48c6.627 0 12-5.373 12-12z\"/>",
    "attrs": {
      "viewBox": "0 0 512 512"
    }
  };
});