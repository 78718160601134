define("ember-svg-jar/inlined/hu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill-rule=\"evenodd\"><path fill=\"#fff\" d=\"M640 480H0V0h640z\"/><path fill=\"#388d00\" d=\"M640 480H0V320h640z\"/><path fill=\"#d43516\" d=\"M640 160.1H0V.1h640z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 640 480"
    }
  };
});