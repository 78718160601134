define("ember-svg-jar/inlined/bg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#fff\" d=\"M0 0h640v160H0z\"/><path fill=\"#00966e\" d=\"M0 160h640v160H0z\"/><path fill=\"#d62612\" d=\"M0 320h640v160H0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 640 480"
    }
  };
});