define("ember-svg-jar/inlined/tickets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.345.293a.571.571 0 01.706-.253l1.8.715c.26.104.406.382.36.666-.183 1.113.413 2.242 1.476 2.664a2.3 2.3 0 00.85.164c.798 0 1.558-.42 1.993-1.13a.57.57 0 01.695-.229l1.806.718a.593.593 0 01.35.67c-.24 1.217.297 2.466 1.334 3.102.24.147.348.447.247.716l-7.52 20.026a.574.574 0 01-.654.368 2.767 2.767 0 00-.563-.058c-.988 0-1.928.531-2.448 1.425a.574.574 0 01-.709.257l-.991-.394c.034-.139.052-.284.052-.434 0-.64.51-1.16 1.135-1.15.953.015 1.718-.81 1.718-1.79V25.33l.126.05c.103.04.209.06.313.06a.87.87 0 00.819-.598.916.916 0 00-.529-1.136l-.729-.29V7.867l5.832 2.318a.84.84 0 00.312.06.869.869 0 00.81-.57.901.901 0 00-.497-1.155l-6.457-2.566v-2.15c0-.978-.765-1.802-1.716-1.786a1.114 1.114 0 01-1.022-.645c.446-.246.83-.614 1.1-1.08zm-11.107.784c.275 0 .506.203.56.48.217 1.09 1.155 1.91 2.279 1.91 1.124 0 2.062-.82 2.278-1.91a.58.58 0 01.561-.48h1.903c.279 0 .515.208.562.492.019.11.043.218.073.323.3 1.048 1.16 1.85 2.224 2.036a.582.582 0 01.476.578v21.142c0 .287-.202.53-.477.578a2.808 2.808 0 00-1.88 1.274c-.206.323-.35.692-.416 1.088a.576.576 0 01-.563.489h-2.416a2.418 2.418 0 00-.961-1.935 2.27 2.27 0 00-2.742.009 2.409 2.409 0 00-.903 1.458.578.578 0 01-.566.468H3.335a.578.578 0 01-.562-.495 2.916 2.916 0 00-.806-1.573 2.77 2.77 0 00-1.499-.784.58.58 0 01-.468-.579V4.498c0-.285.203-.522.477-.57a2.783 2.783 0 001.518-.811c.4-.414.68-.952.779-1.553a.58.58 0 01.57-.487zm7.822 21.146H3.094c-.472 0-.855.393-.855.879s.383.88.855.88h9.966a.85.85 0 00.728-.421.89.89 0 00.127-.459.868.868 0 00-.855-.88zm0-16.05H3.094c-.472 0-.855.393-.855.879s.383.88.855.88h9.966c.472 0 .855-.394.855-.88s-.383-.88-.855-.88z\"/>",
    "attrs": {
      "width": "28",
      "height": "31",
      "viewBox": "0 0 28 31"
    }
  };
});