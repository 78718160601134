define("ember-svg-jar/inlined/mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.125 0H1.875C.84 0 0 .84 0 1.875v11.25C0 14.16.84 15 1.875 15h16.25C19.16 15 20 14.16 20 13.125V1.875C20 .84 19.16 0 18.125 0zM1.875 1.25h16.25c.344 0 .625.281.625.625v1.617c-.855.723-2.078 1.719-5.883 4.738-.66.524-1.96 1.786-2.867 1.77-.906.016-2.21-1.246-2.867-1.77C3.328 5.21 2.105 4.215 1.25 3.492V1.875c0-.344.281-.625.625-.625zm16.25 12.5H1.875a.627.627 0 01-.625-.625V5.117c.89.73 2.297 1.86 5.105 4.09.801.64 2.215 2.05 3.645 2.043 1.422.012 2.824-1.387 3.645-2.043 2.808-2.23 4.214-3.36 5.105-4.09v8.008a.627.627 0 01-.625.625z\"/>",
    "attrs": {
      "width": "20",
      "height": "15",
      "viewBox": "0 0 20 15"
    }
  };
});