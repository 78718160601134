define("ember-svg-jar/inlined/arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.35.11L.116.332a.364.364 0 000 .533L6.576 7l-6.46 6.135a.364.364 0 000 .533l.234.222a.41.41 0 00.56 0l6.974-6.624a.362.362 0 000-.532L.91.11a.41.41 0 00-.56 0z\"/>",
    "attrs": {
      "width": "8",
      "height": "14",
      "viewBox": "0 0 8 14"
    }
  };
});