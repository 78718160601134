define("ember-is-mobile/helpers/is-mobile", ["exports", "@ember/component/helper", "@ember/utils", "@ember/service", "@ember/debug", "@ember/object"], function (_exports, _helper, _utils, _service, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _helper.default.extend({
    isMobile: (0, _service.inject)(),
    compute(params) {
      (false && !((0, _utils.isPresent)(params[0])) && (0, _debug.assert)('Missing parameter for the is-mobile helper', (0, _utils.isPresent)(params[0])));
      const isMobile = (0, _object.get)(this, 'isMobile');
      return (0, _object.get)(isMobile, params[0]);
    }
  });
});