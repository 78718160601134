define("ember-svg-jar/inlined/za", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><clipPath id=\"a\"><path fill-opacity=\".7\" d=\"M-71.9 0h682.7v512H-71.9z\"/></clipPath></defs><g clip-path=\"url(#a)\" transform=\"translate(67.4) scale(.93748)\"><g fill-rule=\"evenodd\" stroke-width=\"1pt\"><path fill=\"#000001\" d=\"M-71.9 407.8V104.4L154 256.1z\"/><path fill=\"#000c8a\" d=\"M82.2 512.1l253.6-170.6H696V512H82.2z\"/><path fill=\"#e1392d\" d=\"M66 0h630v170.8H335.7S69.3-1.7 66 0\"/><path fill=\"#ffb915\" d=\"M-71.9 64v40.4L154 256-72 407.8v40.3l284.5-192z\"/><path fill=\"#007847\" d=\"M-71.9 64V0h95l301.2 204h371.8v104.2H324.3L23 512h-94.9v-63.9l284.4-192L-71.8 64z\"/><path fill=\"#fff\" d=\"M23 0h59.2l253.6 170.7H696V204H324.3zm0 512.1h59.2l253.6-170.6H696v-33.2H324.3L23 512z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 640 480"
    }
  };
});