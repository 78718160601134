define("ember-svg-jar/inlined/magnify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" stroke-width=\"2\"><path d=\"M14.5 14.5L20 20\"/><circle cx=\"8.5\" cy=\"8.5\" r=\"7.5\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "21",
      "height": "21",
      "viewBox": "0 0 21 21"
    }
  };
});