define("ember-svg-jar/inlined/twitch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill-rule=\"nonzero\" fill=\"none\"><path d=\"M906.827 218.169H842.02v-80.805H825.82v80.805h-64.806V16.2h64.806v56.527h48.604l32.403 32.322V218.17m-161.933-80.805h-64.806v16.201h64.806v64.644h-97.16l-32.404-32.322V105.05l32.403-32.322h97.16v64.636m-145.74 0h-56.705v16.202h56.705v64.643h-89.06l-32.402-32.321V16.2h64.806v56.527h56.705l-.049 64.636M461.514 56.567h-64.806V16.201h64.806v40.366m0 161.602h-64.806V72.729h64.806v145.44m-81.007 0h-194.28l-32.403-32.322V72.728h64.806v80.797h16.202V72.728h64.806v80.797h16.2V72.728h64.807l-.138 145.441m-242.86-80.805h-56.64v16.201h56.705v64.644H48.604l-32.403-32.322V16.201h64.806v56.527h56.705l-.065 64.636m744.894-80.797h-40.504V0h-88.872L712.28 56.567h-72.64L615.34 80.805V56.567h-56.705V0H380.547v56.567H129.952L89.108 0H0v193.931l113.353 113.086h113.354v-32.321l32.403 32.321h218.597v-32.321l32.403 32.321h105.237v-32.321l32.403 32.321h202.42l72.907-72.728V96.965L882.54 56.567\" class=\"text-on-background fill-current\"/><path d=\"M761.087 218.168h64.773v-80.804h16.193v80.804h64.774V105.05L874.44 72.728h-48.58V16.17h-64.773v202m-283.38-32.323l32.38 32.322h89.066v-64.644h-56.672v-16.16h56.672V72.728h-56.672V16.17h-64.774v169.678m137.64 0l32.387 32.322h97.16v-64.645H680.12v-16.16h64.774V72.728h-97.16l-32.387 32.322v80.797M396.741 16.169h64.773v40.398h-64.773V16.17M137.647 72.73h-56.68v-56.56H16.194v169.678l32.386 32.322h89.067v-64.645h-56.68v-16.16h56.68V72.728m16.194 113.119l32.378 32.322h194.329V72.729h-64.774v80.796h-16.202V72.728h-64.765v80.797h-16.193V72.728h-64.773v113.119m242.9-113.119h64.773v145.44h-64.773V72.729\" class=\"text-background fill-current\"/></g>",
    "attrs": {
      "viewBox": "0 0 924 308"
    }
  };
});