define("ember-svg-jar/inlined/zoom-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.588 0H1.411C.636 0 0 .652 0 1.429c0 .776.635 1.428 1.412 1.428h9.177c.776 0 1.411-.652 1.411-1.428C12 .652 11.365 0 10.588 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "3",
      "viewBox": "0 0 12 3"
    }
  };
});