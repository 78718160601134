define("ember-svg-jar/inlined/arrow-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M9.5 17.357L1.545 9.402l1.06-1.061 6.145 6.143V1.296h1.5v13.188l6.144-6.143 1.061 1.06L9.5 17.358z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "19",
      "height": "18",
      "viewBox": "0 0 19 18"
    }
  };
});