define("ember-svg-jar/inlined/lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1022.915 834.17q0-53-37.5-90.5t-90.5-37.5q-53 0-90.5 37.5t-37.5 90.5q0 37 19 67t51 47l-69 229q-5 15 5 28t26 13h192q16 0 26-13t5-28l-69-229q32-17 51-47t19-67zm-384-256h512v-192q0-106-75-181t-181-75q-106 0-181 75t-75 181v192zm832 96v576q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-576q0-40 28-68t68-28h32v-192q0-184 132-316t316-132q184 0 316 132t132 316v192h32q40 0 68 28t28 68z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 -256 1792 1792"
    }
  };
});