define("ember-svg-jar/inlined/default-tier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Group 4</title><path d=\"M17.678 75.79a8.407 8.407 0 0111.804 11.804l7.56 7.56a3 3 0 004.243 0l47.87-47.87a3 3 0 000-4.242l-7.52-7.52A8.405 8.405 0 0169.75 23.638l-8.102-8.102a3 3 0 00-4.242 0l-47.87 47.87a3 3 0 000 4.242l8.142 8.143z\" fill=\"none\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "99",
      "height": "99",
      "viewBox": "0 0 99 99",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});